import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  ScriptableChartContext,
  TimeScale,
  Title,
  Tooltip,
  ChartData,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import styled, { StyledComponent, useTheme } from 'styled-components';

import { zIndexes } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useOnClickOutside } from '@core/hook';
import { getStaticPathIconFolder } from '@core/media';
import { EColor, EZIndexName, ThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { Image } from '../image';
import { Box, BoxProps } from '../layout';
import { TooltipChart } from './TooltipChart';
import { TooltipChartInvestment } from './TooltipChartInvestment';
import { ChartProps, IPoint, TooltipType } from './interface-charts';
import { getChartOptions, getLanguageAdapter } from './utils';

const COMPENSATION_TOOLTIP_TOP = 70;
const COMPENSATION_INDENT = 50;

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  Filler,
  Title,
  annotationPlugin,
  PointElement,
  Tooltip,
  Legend,
  TimeScale,
);

export const Chart = ({
  dataSeries,
  height = '500px',
  currency,
  language,
  timeUnit,
  metalIso,
  hasLogo = true,
  datasets: datasetsData,
  tooltipType = 'default',
  compensationTooltipTop = COMPENSATION_TOOLTIP_TOP,
  annotations,
}: ChartProps) => {
  const theme = useTheme() as ThemeProps;
  const { palette } = theme;
  const { getColor, getBackgroundColor } = getPaletteHandlers(palette);

  const { isPhone, isMobile } = useContextMediaQuery();

  const [tooltip, setTooltip] = useState<TooltipType>({
    datasetIndex: 0,
    opacity: 0,
    top: 0,
    left: 0,
    date: 0,
    price: 0,
    priceOunce: 0,
    growth: 0,
    formattedValue: '',
  });

  const baseStyles = {
    borderColor: getColor(EColor.SECONDARY),
    backgroundColor: (context: ScriptableChartContext) => {
      if (!context?.chart?.chartArea) {
        return undefined;
      }
      const {
        ctx,
        chartArea: { top, bottom },
      } = context.chart;
      const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
      gradientBg.addColorStop(0, 'rgb(25, 129, 255, 0.8)');
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      gradientBg.addColorStop(0.25, 'rgb(25, 129, 255, 0.8)');
      gradientBg.addColorStop(1, 'rgb(255, 255, 255, 0)');
      return gradientBg;
    },
    fill: true,
    pointBorderColor: getColor(EColor.TRANSPARENT),
    pointBackgroundColor: getBackgroundColor(EColor.TRANSPARENT),
    pointHoverBackgroundColor:
      tooltip.opacity === 0
        ? getBackgroundColor(EColor.TRANSPARENT)
        : getBackgroundColor({
            semanticType: EColor.NEUTRAL,
            variant: EColor.LIGHT,
          }),
    pointHoverBorderColor:
      tooltip.opacity === 0
        ? getBackgroundColor(EColor.TRANSPARENT)
        : getBackgroundColor({
            commonType: EColor.BLACK,
            intensity: EColor.R900,
          }),
  };

  const data: ChartData<'line', IPoint[], unknown> = {
    datasets: datasetsData
      ? datasetsData.map((dataset, index) => {
          if (tooltip.datasetIndex !== index) {
            return {
              ...baseStyles,
              ...dataset,
              pointHoverBorderColor: getBackgroundColor(EColor.TRANSPARENT),
              pointHoverBackgroundColor: getBackgroundColor(EColor.TRANSPARENT),
            };
          }

          return { ...baseStyles, ...dataset };
        })
      : [
          {
            data: dataSeries,
            ...baseStyles,
          },
        ],
  };

  const chartRef = useRef(null);

  const localeAdapter = getLanguageAdapter(language);

  const options = getChartOptions({
    dataSeries,
    localeAdapter,
    theme,
    language,
    currency,
    tooltip,
    setTooltip,
    chartRef,
    timeUnit,
    isPhone,
    isMobile,
    annotations,
  });

  const tooltipRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, () => {
    setTooltip((prevState) => ({ ...prevState, opacity: 0 }));
  });

  useEffect(() => {
    if (isPhone && tooltipRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const tooltipHalfWidth = tooltipRef.current.offsetWidth / 2;

      // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const chartWidth = chartRef?.current?.chartArea?.width;

      if (tooltip.left < tooltipHalfWidth) {
        tooltipRef.current.style.left = `${tooltipHalfWidth}px`;
      } else if (tooltip.left > chartWidth - tooltipHalfWidth) {
        tooltipRef.current.style.left = `${chartWidth - tooltipHalfWidth}px`;
      } else {
        tooltipRef.current.style.left = `${tooltip.left}px`;
      }
    } else {
      tooltipRef.current.style.left = `${tooltip.left}px`;
    }
  }, [tooltip.left]);

  return (
    <Box
      ref={containerRef}
      paddingTop={COMPENSATION_INDENT}
      marginTop={-COMPENSATION_INDENT}
      position="relative"
      height={height}
      onMouseEnter={() => setTooltip((prevState) => ({ ...prevState, opacity: 1 }))}
      onMouseLeave={() => setTooltip((prevState) => ({ ...prevState, opacity: 0 }))}
    >
      <Line options={options} data={data} ref={chartRef} />
      {hasLogo && (
        <Box
          position={'absolute'}
          right={isPhone ? '50px' : '100px'}
          bottom={isPhone ? '68px' : '86px'}
        >
          <Image
            src={getStaticPathIconFolder('charts/ga-logo.svg')}
            width={132}
            height={24}
            alt={'GoldAvenue'}
          />
        </Box>
      )}
      <StyledTooltip
        top={tooltip.top + compensationTooltipTop + COMPENSATION_INDENT}
        opacity={tooltip.price ? tooltip.opacity : 0}
        ref={tooltipRef}
      >
        {tooltipType === 'investment' ? (
          <TooltipChartInvestment
            tooltip={tooltip}
            language={language}
            currency={currency}
            timeUnit={timeUnit}
          />
        ) : (
          <TooltipChart
            tooltip={tooltip}
            language={language}
            currency={currency}
            metalIso={metalIso}
            timeUnit={timeUnit}
          />
        )}
      </StyledTooltip>
    </Box>
  );
};

const StyledTooltip: StyledComponent<'div', object, BoxProps, string> = styled.div.attrs<BoxProps>(
  ({ opacity, left, top }) => ({
    style: {
      left,
      top,
      opacity,
      pointerEvents: opacity === 0 ? 'none' : 'auto',
    },
  }),
)`
  position: absolute;
  z-index: ${zIndexes[EZIndexName.POPOVER]};
  transform: translateX(-50%) translateY(-200%);
`;
