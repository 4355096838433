import type { ChartOptions, TimeUnit, TooltipModel } from 'chart.js';
import { Locale } from 'date-fns';
import { de, enUS, fr, it } from 'date-fns/locale';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';

import { EColor, ECurrency, EFontWeight, ELanguageTags, EUnit, ThemeProps } from '@core/type';
import { formatCurrency, getPaletteHandlers } from '@core/util';

import type { IPoint, TooltipType } from './interface-charts';

const getYAxisPosition = (dataSeries: IPoint[], isPhone: boolean, isMobile: boolean) => {
  if (!dataSeries) {
    return 'left';
  }

  if (!isPhone && !isMobile) {
    return 'left';
  }

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const yAxisPositionPadding = isPhone ? 0.15 : 0.07;

  const firstDateSinceEpoch = new Date(dataSeries[0]['x']).getTime();
  const lastDateSinceEpoch = new Date(dataSeries[dataSeries.length - 1]['x']).getTime();

  return {
    x: firstDateSinceEpoch + yAxisPositionPadding * (lastDateSinceEpoch - firstDateSinceEpoch),
  };
};

const getDatasetIndex = (tooltipModel: TooltipModel<'line'>) => {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (tooltipModel.dataPoints.length === 2) {
    // @ts-expect-error _eventPosition property is not in chart.js types
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return Math.ceil(tooltipModel.caretX - tooltipModel._eventPosition.x) < 0 ? 1 : 0;
  }

  return 0;
};

export const getChartOptions = ({
  dataSeries,
  localeAdapter,
  theme,
  language,
  currency,
  tooltip,
  setTooltip,
  chartRef,
  timeUnit = EUnit.MONTH,
  isPhone,
  isMobile,
  annotations: annotationsData,
}: {
  dataSeries: IPoint[];
  localeAdapter: Locale;
  theme: ThemeProps;
  language: ELanguageTags;
  currency: ECurrency;
  tooltip: TooltipType;
  setTooltip: Dispatch<SetStateAction<TooltipType>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartRef: MutableRefObject<any>;
  timeUnit: TimeUnit;
  isPhone: boolean;
  isMobile: boolean;
  annotations?: object;
}) => {
  const {
    palette,
    typography: {
      font: fontFamily,
      fontWeight: { [EFontWeight.REGULAR]: regularFontWeight },
    },
  } = theme;
  const { getColor, getTextColor } = getPaletteHandlers(palette);

  const options: ChartOptions<'line'> = {
    animation: false,
    elements: {
      point: {
        radius: 3,
      },
      line: {
        borderWidth: 1.2,
        tension: 0.5,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: timeUnit,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        min: dataSeries[0]?.['x'],
        adapters: {
          date: {
            locale: localeAdapter,
          },
        },
        ticks: {
          autoSkip: true,
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          autoSkipPadding: isPhone ? 10 : 20,
          minRotation: 0,
          maxRotation: 0,
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          padding: isPhone ? 24 : 32,
          color: getTextColor(EColor.SECONDARY),
          font: {
            family: fontFamily,
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            size: isPhone ? 10 : 12,
            weight: regularFontWeight,
          },
        },
      },
      y: {
        border: {
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          dash: [4, 4],
          display: false,
        },
        grid: {
          drawTicks: false,
        },
        position: getYAxisPosition(dataSeries, isPhone, isMobile),
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        suggestedMin: 0.99 * Math.min(...dataSeries.map(({ y }) => y)),
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        suggestedMax: 1.01 * Math.max(...dataSeries.map(({ y }) => y)),
        ticks: {
          padding: 16,
          color: getTextColor(EColor.SECONDARY),
          font: {
            family: fontFamily,
            weight: regularFontWeight,
          },
          callback: (value) => formatCurrency(language, currency, Number(value)),
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
      axis: 'x',
    },
    plugins: {
      legend: {
        display: false,
      },
      filler: {
        propagate: false,
      },
      title: {
        display: false,
      },
      annotation: {
        animations: {
          numbers: {
            properties: [],
            type: 'number',
          },
        },
        common: {
          drawTime: 'beforeDatasetsDraw',
        },
        annotations: {
          line1: {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            borderDash: [5, 5],
            display: tooltip.opacity !== 0,
            type: 'line',
            xMin: tooltip.date,
            xMax: tooltip.date,
            borderColor: getColor({ commonType: EColor.BLACK, intensity: EColor.R900 }),
            borderWidth: 1,
            z: -1,
          },
          ...annotationsData,
        },
      },
      tooltip: {
        mode: 'index',
        enabled: false,
        intersect: false,
        external: (context) => {
          const tooltipModel = context.tooltip;
          const datasetIndex = getDatasetIndex(tooltipModel);

          if (!chartRef?.current) {
            return;
          }

          const { y, tooltipData } = dataSeries.find(
            ({ y }) => y === tooltipModel.dataPoints[datasetIndex].parsed['y'],
          );

          const newTooltipData: TooltipType = {
            opacity: 1,
            left: tooltipModel.dataPoints[datasetIndex].element.x,
            top: tooltipModel.dataPoints[datasetIndex].element.y,
            date: tooltipModel.dataPoints[datasetIndex].parsed['x'],
            price: y,
            formattedValue: tooltipModel.dataPoints[datasetIndex].formattedValue,
            datasetIndex,
            ...tooltipData,
          };

          if (
            tooltip.left !== newTooltipData.left ||
            tooltip.top !== newTooltipData.top ||
            tooltip.date !== newTooltipData.date ||
            tooltip.price !== newTooltipData.price ||
            tooltip.formattedValue !== newTooltipData.formattedValue
          ) {
            setTooltip(newTooltipData);
          }
        },
      },
    },
  };

  return options;
};

export const getLanguageAdapter = (language: ELanguageTags) => {
  if (language === ELanguageTags.DE_DE) {
    return de;
  }
  if (language === ELanguageTags.IT_IT) {
    return it;
  }
  if (language === ELanguageTags.FR_FR) {
    return fr;
  }
  return enUS;
};
